@import "./colors.scss";
.section{
    padding:20px 0;
    min-height:500px;

    &__header{
     font-size: 40px;
     font-weight: bold;
     text-align: center;
     text-transform: uppercase;   
     color:$primaryColor;

     @media (max-width:767px){
        font-size:30px;
        margin-top:0;
     }
    }

    &__text{
        font-size:18px;
        line-height: 120%;
        margin:30px 0;

        @media (max-width:767px){
            font-size:14px;
        }
    }

    ol{
        padding-left:20px;
        li{
            margin:10px 0;
        }
    }
}