@import './colors.scss';
@import './style.scss';

.header {

    &__container {

        @extend .container;
        display: flex;
        justify-content: center;
    }

    &__logo {

        display: inline-block;
        text-align: center;

        img {
            display: block;
            max-width: 600px;
        width:100%;

            @media (max-width:767px){
                max-width: 400px;
            }
        }

    }
}