@import './colors.scss';
@import './style.scss';

.menu {
    background: $primaryColor;
    padding:15px 0;
    
    @media (max-width:767px){
        position:fixed;
        opacity:0;
        visibility: hidden;
        z-index: 99;
        top:0;
        right:0;
        left:0;
        bottom:0;
        transition: all 0.3s;
        padding:40px 0;
        overflow-y: auto;

           &--show {
               opacity:1;
               visibility: visible
           }
    }

 

    &__container {
        @extend .container;

        @media (max-width:767px){
            display:flex;
            align-items: center;
            justify-content: center;
        }
        
    }

    ul {
        list-style-type: none;
        padding:0;
        margin:0;
        display:flex;
        align-items: center;
        justify-content: center;
        @media (max-width:767px){
            flex-direction: column;
        }
     

        li{
            margin:0 40px;

            @media (max-width:767px){
                margin:10px 0;
            }
            a{
                color:$white;
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                transition: all 0.3s;

                &.active{
                    color: $secondaryColor;
                }
            }

            &:hover{
                &>a{
                    color:$secondaryColor;
                }
            }
        }
    }

}

.hamburger {
    border:0;
    background:rgba(0,0,0,0);
    font-size:40px;
    color:$secondaryColor;
    position:fixed;
    z-index: 100;
    top:15px;
    right:15px;
    display:none;

    @media (max-width:767px){
        display:block;
    }
}