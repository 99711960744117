@import './colors.scss';
@import './style.scss';


.footer{
    padding:20px 0;
    background: $primaryColor;

    &__container{
        @extend .container;
    }

    &__text{
        color:$white;
        font-size:12px;
        margin:10px 0;
        text-align: center;

        a{
            text-decoration: none;
            color:inherit;
          
            
        }
    }

    &__logo{
        display:inline-block;
        position:relative;
        top:3px;
        margin-left:5px;
    }
}

a{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}